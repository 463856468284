import { getCsrfToken, signIn } from "next-auth/react";
import { useRouter } from "next/router";

import { Signin } from "@workspace/component-library/pages/auth";

import { withAuth } from "../api/auth/[...nextauth]";

type SigninPagePropType = {
  csrfToken: string;
};

type ERROR_TYPE =
  | "EmailCreateAccount"
  | "EmailSignin"
  | "SessionRequired"
  | "OAuthCallback"
  | "OAuthSignin";

const SigninPage = ({ csrfToken }: SigninPagePropType) => {
  const router = useRouter();

  const { error } = router.query as { error: ERROR_TYPE };

  return <Signin signIn={signIn} csrfToken={csrfToken} error={error} />;
};

export const getServerSideProps = withAuth(async (context) => {
  const csrfToken = await getCsrfToken(context);
  return {
    props: {
      csrfToken,
    },
  };
});

export default SigninPage;
